import React from 'react'
import LeftBarSniper from './LeftBarSniper'

export default function Leftbar(props) {
  return (
    <div
      class='css-1mdmsw7'
      style={(props.leftBar && { width: '345px' }) || { width: '0px' }}
    >
      <LeftBarSniper {...props} />
      <div style={{ borderTop: '1px solid rgb(48, 48, 48)' }}></div>
    </div>
  )
}
