import React from 'react'

export default function SpeedometerIcon({
  color = 'grey',
  width = 16,
  height = 16
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 11'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M10.1818 0H5.81818V1.45455H10.1818V0ZM2.90909 2.90909H5.81818V1.45455H2.90909V2.90909ZM1.45455 4.36364H2.90909V2.90909H1.45455V4.36364ZM1.45455 8.72727V4.36364H-4.76837e-07V8.72727H1.45455ZM1.45455 8.72727V10.1818H2.90909V8.72727H1.45455ZM16 4.36364H14.5455V8.72727H16V4.36364ZM14.5455 8.72727H13.0909V10.1818H14.5455V8.72727ZM6.54545 5.81818H9.45455V4.36364H10.9091V2.90909H12.3636V1.45455H13.8182V2.90909H12.3636V4.36364H10.9091V5.81818H9.45455V8.72727H6.54545V5.81818Z'></path>
    </svg>
  )
}
