import React from 'react'

export default function Header() {
  return (
    <header className='header-wrap'>
      <div
        spacing='16px'
        direction='row'
        className='Flex-sc-s34pwh-0 Stack-sc-aw8yms-0 ivoMik dQWtsp'
        style={{ flex: '1 1 0%' }}
      >
        <a
          href='/'
          to='/'
          className='Link__StyledRouterLink-sc-1xumirv-0 hsrcZD'
          style={{ display: 'flex' }}
        >
          <img src='/logo.png' width='68px' alt='home' />
        </a>
      </div>
      <div className='Flex-sc-s34pwh-0 ivoMik' style={{ flex: '1 1 0%' }}>
        <div
          spacing='16px'
          direction='row'
          className='Flex-sc-s34pwh-0 Stack-sc-aw8yms-0 eVBAnx dQWtsp'
          style={{ flex: '1 1 0%', position: 'relative' }}
        ></div>
        <div className='Flex-sc-s34pwh-0 Navbar__WalletContainer-sc-3sqn9y-0 hbhzdg fBtQpu'>
          <button
            color='grey300'
            className='Link__StyledRouterLink-sc-1xumirv-0 hsrcZD'
            style={{ position: 'relative', zIndex: '2' }}
          >
            <div
              color='grey300'
              transform='uppercase'
              className='Text__TextRoot-sc-m23s7f-0 UserPoints__AnimatedText-sc-1jdoh7g-0 hFzgGl gOoTJy'
            >
              0 Pts
            </div>
          </button>
          <button className='WalletStyledComponents__WalletButtonRoot-sc-1oc3667-0 gjQcsA'>
            <div color='grey300' className='Text__TextRoot-sc-m23s7f-0 ilBdOD'>
              0.0000
            </div>
            <div
              width='max-content'
              height='max-content'
              name='ethereum'
              color='grey300'
              className='Flex-sc-s34pwh-0 Icon__StyledFlex-sc-1an4bgh-0 iNoznw BHLgi'
            >
              <svg
                width='10'
                height='16'
                viewBox='0 0 10 16'
                fill='grey'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M4.99929 0H4.33272V0.666572H3.99943V1.33314H3.33286V2.66629H2.66629V3.33286H1.99972V4.666H1.33314V5.33257H0.666572V6.66572H0V7.33229H0.666572V7.99886H1.99972V8.66543H3.33286V9.332H4.33272V9.99858H4.99929V9.332H5.99915V8.66543H7.33229V7.99886H8.66543V7.33229H9.33201V6.66572H8.66543V5.33257H7.99886V4.666H7.33229V3.33286H6.66572V2.66629H5.99915V1.33314H5.33257V0.666572H4.99929V0ZM3.33286 8.66543V7.99886H1.99972V7.33229H0.666572V6.66572H1.33314V5.33257H1.99972V4.666H2.66629V3.33286H3.33286V2.66629H3.99943V1.33314H4.33272V8.66543H3.33286Z'
                ></path>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M0 9.332H0.666572V10.6651H1.33314V11.9983H1.99972V12.6649H2.66629V13.999H3.33286V14.6656H3.99943V15.3334H4.33272V16H4.99929V15.3334H4.666V15.3321H5.33257V14.6656H5.99915V13.999H6.66572V12.6649H7.33229V11.9983H7.99886V10.6651H8.66543V9.332H9.33201V8.66543H8.66543V9.332H7.33229V9.99858H5.99915V10.6645L4.99929 10.6651V11.3317H4.33272V10.6651H3.33286V9.99858H1.99972V9.332H0.666572V8.66543H0V9.332ZM1.99972 10.6651H1.33314V9.99858H1.99972V10.6651ZM1.99972 10.6651V11.9983H2.66629V12.6649H3.33286V13.999H3.99943V14.6636H4.666V14.6656H5.33257V14.6636H4.666V11.9983H4.33272V11.3317H3.33286V10.6651H1.99972Z'
                ></path>
              </svg>
            </div>
          </button>
          <button className='Link__StyledRouterLink-sc-1xumirv-0 hsrcZD ConnectedAddress__StyledLink-sc-1wxleqr-0 wohgQ'>
            <div
              color='orange200'
              className='Text__TextRoot-sc-m23s7f-0 eULCUb'
              style={{ height: '100%' }}
            >
              <div
                spacing='8px'
                direction='row'
                className='Flex-sc-s34pwh-0 Stack-sc-aw8yms-0 hbhzdg xReQq'
                style={{ height: '100%' }}
              >
                <div className='Blockie__BlockieRoot-sc-bq3ht6-0 FPZZh'>
                  <img
                    src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAMRJREFUWEdjzD059T8DHlBcdxJFtrfJHJ9yBlLVM446YMBD4IF7HEoa+Cb1DiWOp2d4ovAzZ2zHmwYIqed6JoSin3HUAQMeAuiJED0fo0c4ehpBl0ePY3R59HIEoxwYdQDdQ+Baog9KOYCej0kt2wmpRy9HGEcdMOAhQGplhLciwCJJqP1Acntg1AF0DwF0CwkVVITiHN08gmlg1AE0DwH0BgmhOCS1TUjIAxhtwlEHDHgIECpoKG0TYpQD6Ilw1AH0DgEABloJCF+dOuMAAAAASUVORK5CYII='
                    alt='0x1D63e3E6062190b8872a424C1a9f048109f00319'
                  />
                </div>
                <span>0x1D63e3</span>
              </div>
            </div>
          </button>
        </div>
      </div>
    </header>
  )
}
