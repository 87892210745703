import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ClockIcon from '../../ui/Icons/ClockIcon'
import EthIcon from '../../ui/Icons/EthIcon'
import './../../../styles/table.css'

function createData(name, token, amount, status, action, arrow) {
  return {
    name,
    token,
    amount,
    status,
    action,
    arrow
  }
}

function Row(props) {
  const { row } = props
  const [open, setOpen] = React.useState(false)

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component='th' scope='row'>
          {row.name}
        </TableCell>
        <TableCell align='right'>{row.token}</TableCell>
        <TableCell align='right'>{row.amount}</TableCell>
        <TableCell align='right'>{row.status}</TableCell>
        <TableCell align='right'>{row.action}</TableCell>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                EXPAND
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

Row.propTypes = {
  row: PropTypes.shape({
    token: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    arrow: PropTypes.number.isRequired,
    action: PropTypes.number.isRequired
  }).isRequired
}

const rows = [
  createData('12m', 'PEPE', '14.33', 'WAITING', 'PAUSE'),
  createData('40m', 'PEPE', '14.33', 'FAILED', 'RETRY')
]

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <ClockIcon width={14} height={14} />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    id: 'token',
    numeric: false,
    disablePadding: true,
    label: 'TOKEN'
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: true,
    label: 'AMOUNT'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'STATUS'
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: ''
  },
  {
    id: 'child',
    numeric: false,
    disablePadding: true,
    label: ''
  }
]

const PendingTable = ({ option, heart, editable, deletable, icon }) => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('token')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }
  const isSelected = (name) => selected.indexOf(name) !== -1
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0
  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  )

  function Row(props) {
    const { row, isItemSelected, labelId } = props
    const [open, setOpen] = useState(false)

    return (
      <React.Fragment>
        <TableRow
          hover
          role='checkbox'
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.name}
          selected={isItemSelected}
          sx={{ cursor: 'pointer' }}
        >
          <TableCell component='th' id={labelId} scope='row' padding='none'>
            {row.name}
          </TableCell>
          <TableCell align='left'>
            <div style={{ color: 'white' }}>{row.token}</div>
          </TableCell>
          <TableCell align='left'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'white',
                justifyContent: 'center',
                gap: 5
              }}
            >
              {row.amount}
              <EthIcon width={10} height={16} color='white' />
            </div>
          </TableCell>
          <TableCell align='left'>
            <div
              style={
                ({ color: 'white' },
                (row.status === 'FAILED' && { paddingRight: '7px' }) || {})
              }
            >
              {row.status}
            </div>
          </TableCell>
          <TableCell align='left'>
            <button>
              <div
                style={{
                  border: '1px solid #707070',
                  borderRadius: '5px',
                  textAlign: 'center',
                  color: 'white',
                  fontSize: '12px',
                  padding: '5px 20px',
                  fontFamily: 'Proto Mono'
                }}
              >
                {row.action}
              </div>
            </button>
          </TableCell>
          <TableCell>
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowUpIcon color='disabled' />
              ) : (
                <KeyboardArrowDownIcon color='disabled' />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant='h6' gutterBottom component='div'>
                  expand data
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  Row.propTypes = {
    row: PropTypes.shape({
      token: PropTypes.number.isRequired,
      status: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      history: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number.isRequired,
          customerId: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired
        })
      ).isRequired,
      name: PropTypes.string.isRequired,
      arrow: PropTypes.number.isRequired,
      action: PropTypes.number.isRequired
    }).isRequired
  }

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 336 }}
        aria-labelledby='tableTitle'
        size={dense ? 'small' : 'medium'}
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
        />
        <TableBody>
          {visibleRows.map((row, index) => {
            const isItemSelected = isSelected(row.name)
            const labelId = `enhanced-table-checkbox-${index}`
            return (
              <Row
                key={row.name}
                row={row}
                isItemSelected={isItemSelected}
                labelId={labelId}
              />
            )
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PendingTable
