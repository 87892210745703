import React from 'react'

export default function BarChartIcon({
  color = 'grey',
  width = 16,
  height = 16
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 14'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='3.33228'
        y='5.3335'
        width='1.33333'
        height='1.33333'
        transform='rotate(90 3.33228 5.3335)'
      ></rect>
      <rect
        x='2'
        y='6.6665'
        width='5.33333'
        height='1.33333'
        transform='rotate(90 2 6.6665)'
      ></rect>
      <rect
        x='4.66772'
        y='6.6665'
        width='5.33333'
        height='1.33333'
        transform='rotate(90 4.66772 6.6665)'
      ></rect>
      <rect
        x='8.66772'
        y='3.3335'
        width='1.33333'
        height='1.33333'
        transform='rotate(90 8.66772 3.3335)'
      ></rect>
      <rect
        x='7.33228'
        y='4.6665'
        width='7.33333'
        height='1.33333'
        transform='rotate(90 7.33228 4.6665)'
      ></rect>
      <rect
        x='10'
        y='4.6665'
        width='7.33333'
        height='1.33333'
        transform='rotate(90 10 4.6665)'
      ></rect>
      <rect
        x='14'
        width='1.33333'
        height='1.33333'
        transform='rotate(90 14 0)'
      ></rect>
      <rect
        x='12.6677'
        y='1.3335'
        width='10.6667'
        height='1.33333'
        transform='rotate(90 12.6677 1.3335)'
      ></rect>
      <rect
        x='15.3323'
        y='1.3335'
        width='10.6667'
        height='1.33333'
        transform='rotate(90 15.3323 1.3335)'
      ></rect>
      <path d='M16 12L16 13.3333L-5.82819e-08 13.3333L0 12L16 12Z'></path>
    </svg>
  )
}
