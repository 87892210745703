import React from 'react'

export default function OpenHandIcon({
  color = 'grey',
  width = 16,
  height = 16
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2842_38836)'>
        <rect x='7.5' y='6' width='1.5' height='1.5'></rect>
        <rect
          x='13.5'
          y='3'
          width='1.5'
          height='1.5'
          transform='rotate(-180 13.5 3)'
        ></rect>
        <rect x='12' y='6' width='1.5' height='1.5'></rect>
        <rect
          x='9'
          y='3'
          width='1.5'
          height='1.5'
          transform='rotate(-180 9 3)'
        ></rect>
        <rect x='13.5' y='3' width='1.5' height='3'></rect>
        <rect x='6' y='3' width='1.5' height='3'></rect>
        <rect x='9' y='7.5' width='3' height='1.5'></rect>
        <rect
          x='12'
          y='1.5'
          width='3'
          height='1.5'
          transform='rotate(-180 12 1.5)'
        ></rect>
        <rect
          x='18'
          y='12'
          width='1.5'
          height='3'
          transform='rotate(-180 18 12)'
        ></rect>
        <rect
          x='15'
          y='10.5'
          width='1.5'
          height='1.5'
          transform='rotate(-180 15 10.5)'
        ></rect>
        <rect
          x='18'
          y='9'
          width='3'
          height='1.5'
          transform='rotate(-180 18 9)'
        ></rect>
        <rect
          x='13.5'
          y='12'
          width='1.5'
          height='1.5'
          transform='rotate(-180 13.5 12)'
        ></rect>
        <rect
          x='9'
          y='12'
          width='1.5'
          height='1.5'
          transform='rotate(-180 9 12)'
        ></rect>
        <rect
          x='16.5'
          y='13.5'
          width='1.5'
          height='1.5'
          transform='rotate(-180 16.5 13.5)'
        ></rect>
        <rect
          x='15'
          y='15'
          width='1.5'
          height='1.5'
          transform='rotate(-180 15 15)'
        ></rect>
        <rect
          x='13.5'
          y='16.5'
          width='1.5'
          height='1.5'
          transform='rotate(-180 13.5 16.5)'
        ></rect>
        <rect
          x='12'
          y='18'
          width='1.5'
          height='1.5'
          transform='rotate(-180 12 18)'
        ></rect>
        <rect
          x='10.5'
          y='18'
          width='9'
          height='1.5'
          transform='rotate(-180 10.5 18)'
        ></rect>
        <rect
          x='12'
          y='13.5'
          width='6'
          height='1.5'
          transform='rotate(-180 12 13.5)'
        ></rect>
        <rect
          x='4.5'
          y='12'
          width='1.5'
          height='1.5'
          transform='rotate(-180 4.5 12)'
        ></rect>
        <rect
          x='7.5'
          y='10.5'
          width='3'
          height='1.5'
          transform='rotate(-180 7.5 10.5)'
        ></rect>
        <rect
          x='3'
          y='13.5'
          width='1.5'
          height='1.5'
          transform='rotate(-180 3 13.5)'
        ></rect>
      </g>
      <defs>
        <clipPath id='clip0_2842_38836'>
          <rect width='18' height='18' fill='white'></rect>
        </clipPath>
      </defs>
    </svg>
  )
}
