import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'
import filtersService from '../../services/filters/filters.service'
import { toast } from 'react-toastify'

const initialState = {
  savedQueries: [],
  query: {
    id: nanoid(),
    name: '',
    selected: [],
    telegram_id: null,
    simulateBuys: true
  },
  channel_member: '',
  a: '',
  noob: '',
  referred: [],
  referral_code: '',
  referCodes: [],
  pendingItems: [], // List of pending sale items
  logs: {
    botLogins: [],
    appLogins: [],
    filterCreations: []
  },
  stats: {
    total: 0,
    active: [],
    inactive: [],
    leaderboard: []
  },
  referrer_address: null,
  autobuy: {
    id: nanoid(),
    filterId: null,
    wallets: [],
    gas: [],
    eth: null
  },
  bagsReal: [],
  loading: true,
  telegram_id: null,
  telegram_username: null,
  tier: null,
  cmb_address: null,
  cmb_balance: null,
  cmb_tier: 0,
  sync: {},
  verified: false,
  address: null,
  tokens: null,
  isLoading: false,
  tier: 'og',
    autobuy: {
    id: nanoid(),
    filterId: null,
    wallets: [],
    gas: [],
    eth: null
  },
  "wallets": [],
  autobuyQuery: null,

  user: {
    userId: null
  },
  status: 'idle'
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    /// saved query

    addSavedQuery(state, action) {
      state.savedQueries.push(action.payload)
    },

    editSavedQuery(state, action) {
      state.savedQueries = state.savedQueries.map((sq) =>
        sq.id === action.payload.id ? action.payload : sq
      )
    },

    removeSavedQuery(state, action) {
      state.savedQueries = state.savedQueries.filter(
        (item) => item.id !== action.payload
      )
    },

    resetQuery(state) {
      state.query = {
        id: nanoid(),
        name: '',
        selected: [],
        telegram_id: null
      }
    },

    editQuery(state, action) {

      state.query = action.payload

    },

    deleteBag(state, action) {
      const { id } = action.payload;
      console.log('id', id)
      state.bags = state.bags.filter((bag) => bag.id !== id);
      // console.log('state.bags', state.bags)
    },

    editAutoBuyQuery(state, action) {

      state.autobuy = action.payload

    },

    resetAutobuyQuery(state) {
      state.autobuy = {
        id: nanoid(),
        name: '',
        wallets: [],
        gas: [],
        eth_amount: '',
        max_slippage: '',
        max_gas: ''
      }
    },

    generateCode(state) {
      state.referCodes.push({
        id: nanoid(),
        code: nanoid(),
        users: [
          {
            id: 1,
            name: `Test user`
          }
        ]
      })
    },

    setUser(state, action) {
      state.user = {
        ...state.user,
        ...action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllQueries.pending, (state, action) => {
      state.status = 'pending'
            state.isLoading = true;

    })

    builder.addCase(getAllQueries.fulfilled, (state, action) => {

      state.isLoading = false;

      if (action.payload.error !== null) {

        toast.success(`ERROR: ${action.payload.error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        })

      } else {

      state.savedQueries   = action.payload.profiles
      state.autobuys = action.payload.autobuys
      state.wallets = action.payload.wallets
      state.bags = action.payload.bags
      state.bagsReal = action.payload.bagsReal
      state.cmb_address = action.payload.cmb_address
      state.cmb_balance = action.payload.cmb_balance
      state.cmb_tier = action.payload.cmb_tier
            state.referred = action.payload.referred

      state.referral_code = action.payload.referral_code
      state.tier = action.payload.tier
      state.loading = false
      state.status = 'success'
      

      }

    })

    builder.addCase(addAutoBuy.fulfilled, (state, action) => {

      state.savedQueries   = action.payload.data

    })

    builder.addCase(updateAutoBuy.fulfilled, (state, action) => {

      state.savedQueries   = action.payload.data

    })

    builder.addCase(deleteAutoBuy.fulfilled, (state, action) => {

      state.savedQueries   = action.payload.data

    })

    builder.addCase(toggleFilter.fulfilled, (state, action) => {
      state.savedQueries = action.payload
      state.status = 'success'
    })
    
    builder.addCase(toggleAutoBuy.fulfilled, (state, action) => {

      state.savedQueries = action.payload
      state.status = 'success'
 
    })

    builder.addCase(getStats.fulfilled, (state, action) => {

      // console.log('action.payload', action.payload)
      state.logs  = action.payload.logs
      state.stats = action.payload.stats

      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })

    builder.addCase(saveReferrer.fulfilled, (state, action) => {

      // console.log('action.payload', action.payload)
      // state.logs  = action.payload.logs
      state.referrer_address = action.payload.referrer_address

      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })

    builder.addCase(getTokens.fulfilled, (state, action) => {

      // console.log('action.payload', action.payload)
      state.tokens = action.payload.tokens

      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })

function updateData(oldData, newData) {
  return oldData.map(oldItem => {
    const matchingNewItem = newData.find(newItem => newItem.address === oldItem.address);

    if (matchingNewItem) {
      // If there is a matching item in newData, update the price
      return {
        ...oldItem,
        liquidity_price: matchingNewItem.liquidity_price,
      };
    }

    // If no matching item found, return the original item
    return oldItem;
  });
}

    builder.addCase(getBags.fulfilled, (state, action) => {

      // console.log('action.payload', action.payload)
      // state.bags = action.payload.bags
 
      state.bags =     action.payload.bags



      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })

    builder.addCase(getBagsReal.fulfilled, (state, action) => {

      // console.log('action.payload', action.payload)
      state.bagsReal = action.payload.bagsReal
      state.pendingItems = action.payload.pending_items

      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })

    builder.addCase(deleteWallet.fulfilled, (state, action) => {

      // console.log('action.payload', action.payload)
      // state.bagsReal = action.payload.bagsReal
    

      state.wallets = action.payload.wallets

      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })

    builder.addCase(deleteBagReal.fulfilled, (state, action) => {

      console.log('action.payload', action.payload)
      state.bagsReal = action.payload.bagsReal
    
      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })

    builder.addCase(sellToken.fulfilled, (state, action) => {

      // state.isLoading = false;
      state.bagsReal = action.payload.bagsReal
      state.pendingItems = action.payload.pending_items

      console.log('action.payload', action.payload)

      if (action.payload.error !== null) {


        toast.error(`ERROR: ${action.payload.error}`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })


      } else {
        

        toast.success(`SUCCESS`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })


        state.bagsReal   = action.payload.bagsReal

      }

    })

    builder.addCase(sellToken.rejected, (state, action) => {
      
      // console.log('action.payload', action.payload)

/*)
      toast.error(`NETWORK CONGESTED.`, {
        style: {
          fontFamily: 'Proto Mono',
          fontSize: '14px',
          textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
        },
        position: toast.POSITION.TOP_RIGHT

      })
*/
    })

    builder.addCase(generateWallet.fulfilled, (state, action) => {

      // console.log('action.payload.wallets', action.payload.wallets)

      state.wallets = action.payload.wallets

      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })


    builder.addCase(preVerify.fulfilled, (state, action) => {

      // console.log('action.payload.wallets', action.payload.wallets)

      state.sync = action.payload.sync
      state.cmb_address = action.payload.cmb_address

      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })

    builder.addCase(sellToken.pending, (state, action) => {
      // state.isLoading = true;
    })

    builder.addCase(addQuery.fulfilled, (state, action) => {
      state.savedQueries = action.payload
      state.status = 'success'
    })

    builder.addCase(updateQuery.pending, (state, action) => {
      state.status = 'pending'
    })


    builder.addCase(updateQuery.fulfilled, (state, action) => {
      state.savedQueries = action.payload

      state.status = 'success'
    })

    builder.addCase(deleteQuery.pending, (state, action) => {
      state.status = 'pending'
    })

    builder.addCase(deleteQuery.fulfilled, (state, action) => {

      console.log('action.payload', action.payload)

            if (action.payload.error !== null) {


        toast.error(`ERROR: ${action.payload.error}`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })

      } else {

              state.savedQueries = action.payload.data
                    state.bags =     action.payload.bags

      }


      state.status = 'success'
    })

    builder.addCase(deleteBagQuery.pending, (state, action) => {
      state.status = 'pending'
    })

    builder.addCase(deleteBagQuery.fulfilled, (state, action) => {
      state.bags = action.payload.bags
      console.log('action.payload', action.payload)
      state.status = 'success'
    })

  }
})

export const getAllQueries = createAsyncThunk('getAllQueries', async (data) =>
  filtersService.getAll(data)
)

export const addQuery = createAsyncThunk('addQuery', async (data) =>
  filtersService.addQuery(data)
)

export const updateQuery = createAsyncThunk('updateQuery', async (data) =>
  filtersService.updateQuery(data)
)

export const deleteBagQuery = createAsyncThunk('deleteBagQuery', async (id) =>
  filtersService.deleteBagQuery(id)
)

export const deleteBagReal = createAsyncThunk('deleteBagReal', async (id) =>
  filtersService.deleteBagReal(id)
)

export const deleteWallet = createAsyncThunk('deleteWallet', async (id) =>
  filtersService.deleteWallet(id)
)

export const deleteQuery = createAsyncThunk('deleteQuery', async (id) =>
  filtersService.deleteQuery(id)
)

export const getStats = createAsyncThunk('getStats', async (data) =>
  filtersService.getStats(data)
)

export const getTokens = createAsyncThunk('getTokens', async (data) =>
  filtersService.getTokens(data)
)

export const generateWallet = createAsyncThunk('generateWallet', async (data) =>
  filtersService.generateWallet(data)
)

export const toggleFilter = createAsyncThunk('toggleFilter', async (data) =>
  filtersService.toggleFilter(data)
)

export const addAutoBuy = createAsyncThunk('addAutoBuy', async (data) =>
  filtersService.addAutoBuy(data)
)

export const updateAutoBuy = createAsyncThunk('updateAutoBuy', async (data) =>
  filtersService.updateAutoBuy(data)
)

export const getBags = createAsyncThunk('getBags', async (data) =>
  filtersService.getBags(data)
)

export const getBagsReal = createAsyncThunk('getBagsReal', async (data) =>
  filtersService.getBagsReal(data)
)

export const saveReferrer = createAsyncThunk('saveReferrer', async (data) =>
  filtersService.saveReferrer(data)
)

export const preVerify = createAsyncThunk('preVerify', async (data) =>
  filtersService.preVerify(data)
)

export const toggleAutoBuy = createAsyncThunk('toggleAutoBuy', async (data) =>
  filtersService.toggleAutoBuy(data)
)

export const deleteAutoBuy = createAsyncThunk('deleteAutoBuy', async (id) =>
  filtersService.deleteAutoBuy(id)
)


export const sellToken = createAsyncThunk('sellToken', async (data) =>
  filtersService.sellToken(data)
)