import React from 'react'

export default function FuelIcon({ color = 'grey', width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='1.39136' width='1.3913' height='13.913'></rect>
      <rect
        x='9.73901'
        width='1.3913'
        height='8.34783'
        transform='rotate(90 9.73901 0)'
      ></rect>
      <rect
        x='9.73901'
        y='4.17383'
        width='1.3913'
        height='8.34783'
        transform='rotate(90 9.73901 4.17383)'
      ></rect>
      <rect
        x='11.1304'
        y='13.9131'
        width='1.3913'
        height='11.1304'
        transform='rotate(90 11.1304 13.9131)'
      ></rect>
      <rect x='8.34766' width='1.3913' height='13.913'></rect>
      <rect x='9.73901' y='6.95642' width='2.78261' height='1.3913'></rect>
      <rect x='13.2175' y='4.17383' width='1.3913' height='1.3913'></rect>
      <rect x='13.9128' y='2.78259' width='1.3913' height='1.3913'></rect>
      <rect x='11.1304' width='1.3913' height='1.3913'></rect>
      <rect x='12.522' width='1.3913' height='1.3913'></rect>
      <rect x='12.522' y='2.78259' width='1.3913' height='1.3913'></rect>
      <rect x='13.2175' y='1.39124' width='1.3913' height='1.3913'></rect>
      <rect x='12.522' y='11.8262' width='3.47826' height='1.3913'></rect>
      <rect x='11.1304' y='8.34784' width='1.3913' height='4.86957'></rect>
      <rect x='14.6086' y='4.17383' width='1.3913' height='7.65217'></rect>
    </svg>
  )
}
