import React from 'react'

export default function DotChartIcon({
  color = 'grey',
  width = 16,
  height = 16
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='11.1309' y='1.3916' width='1.3913' height='1.3913'></rect>
      <rect x='12.5215' width='1.3913' height='1.3913'></rect>
      <rect x='13.9126' y='1.3916' width='1.3913' height='1.3913'></rect>
      <rect x='12.5215' y='2.7832' width='1.3913' height='1.3913'></rect>
      <rect x='3.47852' y='4.1748' width='1.3913' height='1.3913'></rect>
      <rect x='4.86914' y='2.7832' width='1.3913' height='1.3913'></rect>
      <rect x='6.2605' y='4.1748' width='1.3913' height='1.3913'></rect>
      <rect x='4.86914' y='5.56641' width='1.3913' height='1.3913'></rect>
      <rect x='9.73926' y='9.73877' width='1.3913' height='1.3913'></rect>
      <rect x='11.1309' y='8.34766' width='1.3913' height='1.3913'></rect>
      <rect x='12.5215' y='9.73877' width='1.3913' height='1.3913'></rect>
      <rect x='11.1309' y='11.1304' width='1.3913' height='1.3913'></rect>
      <rect width='1.3913' height='16'></rect>
      <rect x='1.3916' y='14.6084' width='14.6087' height='1.3913'></rect>
    </svg>
  )
}
