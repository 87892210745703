import cn from 'clsx'
import React, { useTransition } from 'react'
import './Tabs.css'

const Tabs = ({ tabs, activeTab, setActiveTab, isBottomLine = false }) => {
  const [, startTransition] = useTransition()

  const setTab = (i) => {
    startTransition(() => {
      setActiveTab(i)
    })
  }

  return (
    <div className='tabs-ui-component'>
      <ul>
        {tabs.map(({ text, icon }, i) => (
          <li
            key={i}
            onClick={() => setTab(i)}
            className={cn({
              'tabs-ui-component-active': activeTab === i,
              'tabs-ui-component-active-indicator': isBottomLine
            })}
          >
            {icon}
            <span>{text}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Tabs
