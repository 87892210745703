import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import ReactSlider from 'react-slider'
import EthIcon from '../../../../../ui/Icons/EthIcon'
import './../../../../../../styles/slider.css'
import './../../../../../../styles/table.css'

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts'
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						> */}
            {headCell.label}
            {/* {orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null} */}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    id: 'token',
    numeric: false,
    disablePadding: true,
    label: 'TOKEN'
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: true,
    label: 'AMOUNT'
  },
  {
    id: 'initials',
    numeric: false,
    disablePadding: true,
    label: 'INITIALS'
  },
  {
    id: 'values',
    numeric: false,
    disablePadding: true,
    label: 'VALUE'
  },
  {
    id: 'mcap',
    numeric: false,
    disablePadding: true,
    label: 'MCAP'
  },
  {
    id: 'profit',
    numeric: false,
    disablePadding: true,
    label: 'PROFIT'
  },
  {
    id: 'custom',
    numeric: false,
    disablePadding: true,
    label: 'CUSTOM'
  },
  {
    id: 'percent',
    numeric: false,
    disablePadding: true,
    label: ''
  },
  {
    id: 'sell',
    numeric: false,
    disablePadding: true,
    label: ''
  }
]

function createData(
  token,
  amount,
  initials,
  value,
  mcap,
  profit,
  custom,
  percent,
  sell
) {
  return {
    token,
    amount,
    initials,
    value,
    mcap,
    profit,
    custom,
    percent,
    sell
  }
}

const BagsTable = ({
  option,
  heart,
  editable,
  deletable,
  icon,
  setSelectedSell,
  selectedSell,
  setIsAll,
  setPrice
}) => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('token')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [row, setRow] = useState([
    {
      token: 'PEPE 0x12...4642',
      amount: '8.000.000',
      initials: 8.59,
      value: 8.55,
      mcap: 126,
      profit: 1.33,
      custom: true,
      percent: 100
    },
    {
      token: 'PEPE 0x12...4643',
      amount: '8.000.000',
      initials: 8.59,
      value: 8.55,
      mcap: 126,
      profit: 1.33,
      custom: true,
      percent: 100
    },
    {
      token: 'PEPE 0x12...4644',
      amount: '8.000.000',
      initials: 8.59,
      value: 8.55,
      mcap: 126,
      profit: 1.33,
      custom: true,
      percent: 100
    }
  ])

  const rows = row.map((obj) =>
    createData(
      obj.token,
      obj.amount,
      obj.initials,
      obj.value,
      obj.mcap,
      obj.profit,
      obj.custom,
      obj.percent,
      obj.sell
    )
  )

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.token)
      setSelected(newSelected)
      setSelectedSell(newSelected.length)
      setIsAll(newSelected.length === row.length)
      let price = 0
      rows.map((item) => {
        price += Number(item.value)
      })
      setPrice(price.toFixed(2))
      return
    }
    setSelected([])
    setSelectedSell(0)
    setPrice(0)
    setIsAll(false)
  }
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
    setSelectedSell(newSelected.length)
    setIsAll(newSelected.length === row.length)

    let price = 0
    rows.map((item) => {
      if (newSelected.includes(item.token)) {
        price += Number(item.value)
      }
    })
    setPrice(price.toFixed(2))
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }
  const isSelected = (name) => selected.indexOf(name) !== -1
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0
  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, row]
  )
  const onToggleAntiRug = (token, value) => {
    const updateRowList = row.filter((item) => {
      if (item.token === token) {
        item.custom = value
      }
      return item
    })
    setRow(updateRowList)
    console.log(row)
  }
  const setPercent = (value, token) => {
    const updatedRow = row.map((item) => {
      if (item.token === token) {
        return { ...item, percent: value }
      }
      return item
    })
    console.log(updatedRow)
    setRow(updatedRow)
  }

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby='tableTitle'
        size={dense ? 'small' : 'medium'}
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
        />
        <TableBody>
          {row.map((rowData, index) => {
            const isItemSelected = isSelected(rowData.token)
            const labelId = `enhanced-table-checkbox-${index}`
            return (
              <React.Fragment>
                <TableRow
                  hover
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.token}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      color='primary'
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId
                      }}
                      onClick={(event) => handleClick(event, rowData.token)}
                    />
                  </TableCell>
                  <TableCell
                    component='th'
                    id={labelId}
                    scope='row'
                    padding='none'
                  >
                    <div
                      style={{
                        color: 'white',
                        whiteSpace: 'nowrap',
                        textAlign: 'center'
                      }}
                    >
                      {rowData.token}
                    </div>
                  </TableCell>
                  <TableCell align='left'>
                    <div style={{ color: 'grey' }}>{rowData.amount}</div>
                  </TableCell>
                  <TableCell align='left'>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        gap: 5
                      }}
                    >
                      {rowData.initials}
                      <EthIcon width={10} height={16} />
                    </div>
                  </TableCell>
                  <TableCell align='left'>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        gap: 5
                      }}
                    >
                      {rowData.value}
                      <EthIcon width={10} height={16} />
                    </div>
                  </TableCell>
                  <TableCell align='left'>
                    <div style={{ color: 'white' }}>{rowData.mcap}K</div>
                  </TableCell>
                  <TableCell align='left'>
                    <div
                      color='green300'
                      class='Text__TextRoot-sc-m23s7f-0 iMtIGg'
                    >
                      {rowData.profit}%
                    </div>
                  </TableCell>
                  <TableCell align='left'>
                    <label className='toggle-switch'>
                      <input
                        type='checkbox'
                        checked={rowData.custom}
                        onChange={(event) => {
                          onToggleAntiRug(rowData.token, event.target.checked)
                        }}
                      />
                      <span className='switch' />
                    </label>
                  </TableCell>
                  <TableCell align='left'>
                    <div className='rangeslider'>
                      <div
                        style={{
                          width: '80px',
                          height: '14px',
                          paddingLeft: '5px',
                          paddingRight: '5px'
                        }}
                      >
                        <ReactSlider
                          className='customSlider'
                          thumbClassName='customSlider-thumb'
                          trackClassName='customSlider-track'
                          markClassName='customSlider-mark'
                          min={0}
                          max={100}
                          defaultValue={rowData.percent}
                          value={rowData.percent}
                          onChange={(value) => {
                            setPercent(value, rowData.token)
                            console.log(value)
                          }}
                          renderMark={(props) => {
                            if (props.key < rowData.percent) {
                              props.className =
                                'customSlider-mark customSlider-mark-before'
                            } else if (props.key === rowData.percent) {
                              props.className =
                                'customSlider-mark customSlider-mark-active'
                            }
                            return <span {...props} />
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: '30px',
                          borderLeft: '1px solid grey',
                          padding: '3px 5px',
                          color: 'white',
                          textAlign: 'center'
                        }}
                      >
                        {rowData.percent}%
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align='left'>
                    <button
                      style={{
                        border: '1px solid #707070',
                        borderRadius: '5px',
                        textAlign: 'center',
                        color: 'white',
                        fontSize: '12px',
                        padding: '5px 10px',
                        marginRight: '6px',
                        width: '120px'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div>SELL</div>
                        <div>
                          {Number(
                            (rowData.value * rowData.percent * 0.01).toFixed(2)
                          )}{' '}
                          ETH
                        </div>
                      </div>
                    </button>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BagsTable
