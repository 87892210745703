import React, { useState } from 'react'

import cn from 'clsx'
import ChevronLeft from '../../ui/Icons/Chevron/ChevronLeft.js'
import EthIcon from '../../ui/Icons/EthIcon.js'
import OpenHandIcon from '../../ui/Icons/OpenHandIcon.js'
import './../../../styles/bottom.css'
import './../../../styles/dropdown.css'
import './../../../styles/header.css'
import './../../../styles/leftbar.css'
import './../../../styles/main.css'
import './../../../styles/rightbar.css'
import './../../../styles/table.css'
import './Dashboard.css'
import DashboardCollectionsCharts from './DashboardCollectionsCharts.js'
import Header from './Header.js'
import Leftbar from './Leftbar/Leftbar.js'
import MainBox from './MainBox/MainBox.js'
import PendingTable from './PendingTable.js'

export default function Dashboard() {
  const [isToggledAutoApprove, setIsToggledAutoApprove] = useState(false)
  const [isToggledAntiRug, setIsToggledAntiRug] = useState(true)
  const [sippage, setSippage] = useState(5)
  const [gas, setGas] = useState(5)
  const [leftBar, setLeftBar] = useState(true)
  const [rightBar, setRightBar] = useState(true)
  const [amount, setAmount] = useState(8.56)
  const [tokenName, setTokenName] = useState('')
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedSell, setSelectedSell] = useState(0)
  const [isAll, setIsAll] = useState(0)
  const [price, setPrice] = useState(0)
  const [walletOption, setWalletOption] = useState([
    { id: 1, name: 'WALLET #1 (9.8 ETH)' },
    { id: 2, name: 'WALLET #2 (11.2 ETH)' }
  ])

  const onToggleAutoApprove = () =>
    setIsToggledAutoApprove(!isToggledAutoApprove)
  const onToggleAntiRug = () => setIsToggledAntiRug(!isToggledAntiRug)
  const snipeClick = () => {}
  const gasPlus = () => {
    if (gas < 100) {
      setGas(gas + 1)
    }
  }
  const sippagePlus = () => {
    if (sippage < 100) {
      setSippage(sippage + 1)
    }
  }
  const sippageMinus = () => {
    if (sippage > 0) {
      setSippage(sippage - 1)
    }
  }
  const gasMinus = () => {
    if (gas > 0) {
      setGas(gas - 1)
    }
  }

  const addWallet = (value) => {
    setWalletOption((prevOptions) => [
      ...prevOptions,
      { id: walletOption.length, name: value }
    ])
    console.log(walletOption)
  }

  const setEditable = (id) => {
    const updateRowList = walletOption.filter((item) => {
      if (item.id === id) {
        item.editable = !item.editable
      }
      return item
    })
    setWalletOption(updateRowList)
  }

  return (
    <div className='App'>
      <div className='App-wrapper'>
        <Header />
        <div style={{ minHeight: '62px' }}></div>
        <main
          style={{
            flex: '1 1 0%',
            minHeight: '0px',
            display: 'block',
            height: 'calc(100vh - 111px)'
          }}
        >
          <Leftbar
            addWallet={addWallet}
            gas={gas}
            gasMinus={gasMinus}
            gasPlus={gasPlus}
            leftBar={leftBar}
            selectedItems={selectedItems}
            setEditable={setEditable}
            setSelectedItems={setSelectedItems}
            setSippage={setSippage}
            setAmount={setAmount}
            sippage={sippage}
            sippageMinus={sippageMinus}
            sippagePlus={sippagePlus}
            amount={amount}
            snipeClick={snipeClick}
            walletOption={walletOption}
          />
          <div
            class='css-yl3y1i'
            style={{
              paddingLeft: leftBar ? '345px' : '0px',
              paddingRight: rightBar ? '455px' : '0px'
            }}
          >
            {/* { paddingLeft: "345px", paddingRight: "455px" } */}
            <div
              id='COLLECTION_MAIN'
              className='collection__GridItem-sc-1jg82bx-0 gXMROr'
              style={{ position: 'relative' }}
            >
              <button
                class={cn(
                  'IconButton__StyledButton-sc-1fc64g3-0 bPkAjH collection__CollapseStyledIconButton-sc-1jg82bx-1 kehMTM',
                  leftBar ? 'kehMTM-left-open' : 'kehMTM-left-close'
                )}
                onClick={() => {
                  setLeftBar(!leftBar)
                }}
              >
                <div
                  width='20px'
                  height='20px'
                  transform='rotate(90deg)'
                  name='chevron'
                  color='grey300'
                  class={
                    (leftBar &&
                      'Flex-sc-s34pwh-0 Icon__StyledFlex-sc-1an4bgh-0 ejcTzg vKjmN') ||
                    'Flex-sc-s34pwh-0 Icon__StyledFlex-sc-1an4bgh-0 ejcTzg eAPkgd'
                  }
                >
                  <ChevronLeft width={12} height={7} />
                </div>
              </button>
              <button
                class={cn(
                  'IconButton__StyledButton-sc-1fc64g3-0 bPkAjH collection__CollapseStyledIconButton-sc-1jg82bx-1 kehMTM',
                  rightBar ? 'kehMTM-right-open' : 'kehMTM-right-close'
                )}
                onClick={() => {
                  setRightBar(!rightBar)
                }}
              >
                <div
                  width='20px'
                  height='20px'
                  transform='rotate(270deg)'
                  name='chevron'
                  color='grey300'
                  class='Flex-sc-s34pwh-0 Icon__StyledFlex-sc-1an4bgh-0 ejcTzg eAPkgd'
                >
                  <ChevronLeft width={12} height={7} />
                </div>
              </button>
              <MainBox
                tokenName={tokenName}
                setTokenName={setTokenName}
                selectedSell={selectedSell}
                setSelectedSell={setSelectedSell}
                setIsAll={setIsAll}
                setPrice={setPrice}
              />
            </div>
            <div
              id='COLLECTION_ACTIONBAR'
              className='collection__GridItem-sc-1jg82bx-0 actionbar'
            >
              <button
                class='Button__StyledButton-sc-2vjufb-0 hJYBpF'
                style={{
                  display: 'flex',
                  minWidth: '200px',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  color='orange200'
                  transform='uppercase'
                  class='Text__TextRoot-sc-m23s7f-0 iqwNDd'
                >
                  {(isAll && 'SELL ALL') ||
                    (Number(selectedSell) === 1 &&
                      'SELL ' + Number(selectedSell) + ' BAG') ||
                    (Number(selectedSell) > 1 &&
                      'SELL ' + Number(selectedSell) + ' BAGS') ||
                    'SELL'}
                </div>
                <div
                  style={{
                    opacity: '0.5',
                    display: 'flex',
                    alignItems: 'center',
                    pointerEvents: 'none'
                  }}
                >
                  <div
                    color='orange200'
                    class='Text__TextRoot-sc-m23s7f-0 cthsiW'
                  >
                    {price}
                  </div>
                  <div
                    width='max-content'
                    height='max-content'
                    name='ethereum'
                    color='orange200'
                    transform='translateY(-1px)'
                    class='Flex-sc-s34pwh-0 Icon__StyledFlex-sc-1an4bgh-0 iNoznw bfPWzQ'
                  >
                    <EthIcon width={10} height={16} />
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div
            class='css-mhwm4s'
            style={(rightBar && { width: '453px' }) || { width: '0px' }}
          >
            <div
              id='COLLECTION_ACTIVITY'
              className='collection__GridItem-sc-1jg82bx-0 dhSLtB'
            >
              <div class='activity-wrap'>
                <div
                  class='section-header'
                  style={{
                    padding: '0px 12px 0px 15px',
                    marginBottom: '16px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      paddingBottom: '0px',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div
                      spacing='8px'
                      direction='row'
                      class='Flex-sc-s34pwh-0 Stack-sc-aw8yms-0 hbhzdg xReQq'
                    >
                      <div
                        width='20px'
                        height='20px'
                        name='open-hand'
                        color='grey300'
                        class='Flex-sc-s34pwh-0 Icon__StyledFlex-sc-1an4bgh-0 ejcTzg BHLgi'
                      >
                        <OpenHandIcon width={18} height={18} />
                      </div>
                      <h2
                        color='grey100'
                        transform='uppercase'
                        class='Text__TextRoot-sc-m23s7f-0 fPNLtw'
                      >
                        PENDING
                      </h2>
                    </div>
                  </div>
                </div>
                <div class='ActivityView__RowAnimator-sc-1fsogtb-0 idWLMs'>
                  <PendingTable />
                </div>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                height: '1px',
                background: 'rgb(48, 48, 48)',
                position: 'absolute'
              }}
            ></div>
            <DashboardCollectionsCharts />
          </div>
        </main>
      </div>
    </div>
  )
}
