import React from 'react'

export default function ListBulletedSpacedIcon({
  color = 'grey',
  width = 16,
  height = 16
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 14'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='2.63232'
        width='1.31624'
        height='1.31624'
        transform='rotate(90 2.63232 0)'
      ></rect>
      <rect
        x='2.63232'
        y='2.63232'
        width='1.31624'
        height='1.31624'
        transform='rotate(90 2.63232 2.63232)'
      ></rect>
      <rect
        x='3.94873'
        y='1.31641'
        width='1.31624'
        height='1.31624'
        transform='rotate(90 3.94873 1.31641)'
      ></rect>
      <path
        d='M14.3493 1.31641L14.3493 1.26641L14.2993 1.26641L5.08564 1.26641L5.03564 1.26641L5.03564 1.31641L5.03564 2.63265L5.03564 2.68265L5.08564 2.68265L14.2993 2.68265L14.3493 2.68265L14.3493 2.63265L14.3493 1.31641Z'
        stroke='white'
        strokeWidth='0.1'
      ></path>
      <path
        d='M14.3493 6.46143L14.3493 6.41143L14.2993 6.41143L5.08564 6.41143L5.03564 6.41143L5.03564 6.46143L5.03564 7.77766L5.03564 7.82766L5.08564 7.82766L14.2993 7.82766L14.3493 7.82766L14.3493 7.77767L14.3493 6.46143Z'
        stroke='white'
        strokeWidth='0.1'
      ></path>
      <path
        d='M14.3493 11.4873L14.3493 11.4373L14.2993 11.4373L5.08564 11.4373L5.03564 11.4373L5.03564 11.4873L5.03564 12.8035L5.03564 12.8535L5.08564 12.8535L14.2993 12.8535L14.3493 12.8535L14.3493 12.8035L14.3493 11.4873Z'
        stroke='white'
        strokeWidth='0.1'
      ></path>
      <rect y='1.31641' width='1.31624' height='1.31624'></rect>
      <rect
        x='2.63232'
        y='5.02588'
        width='1.31624'
        height='1.31624'
        transform='rotate(90 2.63232 5.02588)'
      ></rect>
      <rect
        x='2.63232'
        y='7.6582'
        width='1.31624'
        height='1.31624'
        transform='rotate(90 2.63232 7.6582)'
      ></rect>
      <rect
        x='3.94873'
        y='6.34229'
        width='1.31624'
        height='1.31624'
        transform='rotate(90 3.94873 6.34229)'
      ></rect>
      <rect y='6.34229' width='1.31624' height='1.31624'></rect>
      <rect
        x='2.63232'
        y='10.0513'
        width='1.31624'
        height='1.31624'
        transform='rotate(90 2.63232 10.0513)'
      ></rect>
      <rect
        x='2.63232'
        y='12.6836'
        width='1.31624'
        height='1.31624'
        transform='rotate(90 2.63232 12.6836)'
      ></rect>
      <rect
        x='3.94873'
        y='11.3672'
        width='1.31624'
        height='1.31624'
        transform='rotate(90 3.94873 11.3672)'
      ></rect>
      <rect y='11.3672' width='1.31624' height='1.31624'></rect>
    </svg>
  )
}
