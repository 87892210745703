import React from 'react'

export default function VerticalStackIcon({
  color = 'grey',
  width = 16,
  height = 16
}) {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='6.66727' y='8' width='2.66667' height='1.33333'></rect>
      <rect
        x='9.33273'
        y='1.3335'
        width='2.66667'
        height='1.33333'
        transform='rotate(-180 9.33273 1.3335)'
      ></rect>
      <rect x='9.33273' y='6.6665' width='2.66667' height='1.33333'></rect>
      <rect
        x='6.66727'
        y='2.6665'
        width='2.66667'
        height='1.33333'
        transform='rotate(-180 6.66727 2.6665)'
      ></rect>
      <rect x='4' y='6.6665' width='2.66667' height='1.33333'></rect>
      <rect
        x='12'
        y='2.6665'
        width='2.66667'
        height='1.33333'
        transform='rotate(-180 12 2.6665)'
      ></rect>
      <rect x='1.33228' y='5.3335' width='2.66667' height='1.33333'></rect>
      <rect y='4' width='1.33333' height='1.33333'></rect>
      <rect x='14.6668' y='4' width='1.33333' height='1.33333'></rect>
      <rect
        x='14.6668'
        y='4'
        width='2.66667'
        height='1.33333'
        transform='rotate(-180 14.6668 4)'
      ></rect>
      <rect x='12' y='5.3335' width='2.66667' height='1.33333'></rect>
      <rect
        x='4'
        y='4'
        width='2.66667'
        height='1.33333'
        transform='rotate(-180 4 4)'
      ></rect>
      <rect x='6.66727' y='11.3335' width='2.66667' height='1.33333'></rect>
      <rect x='9.33273' y='10' width='2.66667' height='1.33333'></rect>
      <rect x='4' y='10' width='2.66667' height='1.33333'></rect>
      <rect x='1.33228' y='8.66699' width='2.66667' height='1.33333'></rect>
      <rect x='12' y='8.66699' width='2.66667' height='1.33333'></rect>
      <rect y='7.3335' width='1.33333' height='1.33333'></rect>
      <rect x='14.6668' y='7.3335' width='1.33333' height='1.33333'></rect>
      <rect x='6.66727' y='14.6665' width='2.66667' height='1.33333'></rect>
      <rect x='9.33273' y='13.333' width='2.66667' height='1.33333'></rect>
      <rect x='4' y='13.333' width='2.66667' height='1.33333'></rect>
      <rect x='1.33228' y='12' width='2.66667' height='1.33333'></rect>
      <rect x='12' y='12' width='2.66667' height='1.33333'></rect>
      <rect y='10.6665' width='1.33333' height='1.33333'></rect>
      <rect x='14.6668' y='10.6665' width='1.33333' height='1.33333'></rect>
    </svg>
  )
}
