import React from 'react'

import { useSelector } from 'react-redux'
import { useActions } from '../../../../../../hooks/use-actions'

export default function Loans() {
  const { value } = useSelector((state) => state.dashboardSlice)
  const { increment, decrement, incrementByAmount } = useActions()

  return (
    <div>
      <div>Count is {value}</div>
      <button
        style={{ background: 'rgb(246, 174, 45)', padding: '6px 12px' }}
        aria-label='Increment value'
        onClick={() => increment()}
      >
        Increment
      </button>
      <button
        style={{ background: 'rgb(246, 174, 45)', padding: '6px 12px' }}
        aria-label='Decrement value'
        onClick={() => decrement()}
      >
        Decrement
      </button>
      <button
        style={{ background: 'rgb(246, 174, 45)', padding: '6px 12px' }}
        aria-label='Decrement value'
        onClick={() => incrementByAmount(10)}
      >
        + 10
      </button>
    </div>
  )
}
