import React from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../../hooks/use-actions';
import cn from 'clsx';
import s from './Wallet.module.scss';
import { BsCheck } from 'react-icons/bs';

export default function Wallets() {
  const { wallets } = useSelector((state) => state.modalSlice);
  const query = useSelector((state) => state.modalSlice.autobuy);

  const walletOptions = {
    options: [
      {
        id: 1,
        title: 'SELECT A WALLET',
        items: wallets,
      },
    ],
  };

  const noWalletSelected = query.wallets.length === 0;
  const noWalletsAvailable = wallets.length === 0;

  return (
    <div className={s.container}>
      <div className={s.full}>
        {noWalletsAvailable ? (




            <div className={s.profileCard}>
    <div className={s.profileHeader}>
      <div className={s.profileTitle}>SELECT A WALLET</div>
    </div>
    <div className={s.profileContainer}>
       <p className={s.value2}>No wallets found</p> 
    </div>
  </div>



         
        ) : (
          walletOptions.options.map((option, i) => (
            <WalletCard key={i} item={option} />
          ))
        )}

      </div>
    </div>
  );
}

const WalletCard = ({ item }) => (
  <div className={s.profileCard}>
    <div className={s.profileHeader}>
      <div className={s.profileTitle}>{item.title}</div>
    </div>
    <div className={s.profileContainer}>
      {item.items.map((wallet, idx) => (
        <WalletRow key={idx} item={wallet} />
      ))}
    </div>
  </div>
);

const WalletRow = ({ item }) => {
  const { editAutoBuyQuery } = useActions();
  const query = useSelector((state) => state.modalSlice.autobuy);


/*
  const handleToggle = () => {
    const updatedWallets = query.wallets.includes(item.id)
      ? query.wallets.filter((id) => id !== item.id)
      : [...query.wallets, item.id];
    editAutoBuyQuery({ ...query, wallets: updatedWallets });
  };
*/


  const handleToggle = () => {
    // Only one wallet can be selected at a time
    const updatedWallets = query.wallets.includes(item.id)
      ? [] // Deselecting if clicked wallet is already selected
      : [item.id]; // Selecting the new wallet, clearing others

    editAutoBuyQuery({ ...query, wallets: updatedWallets });
  };

  const isChecked = query.wallets.includes(item.id);

  return (
    <div className={s.profileRow}>
      <div className={`${s.value} ${s.bag}`}>{item.name}</div>
      <div className={`${s.value} ${s.highlight}`}>
        <div className={s.toggleSwitch}>
          <input
            type="checkbox"
            id={`toggle-${item.id}`}
            className={s.toggleInput}
            checked={isChecked}
            onChange={handleToggle}
          />
          <label htmlFor={`toggle-${item.id}`} className={s.toggleLabel}>
            <span className={s.toggleIcon}></span>
          </label>
        </div>
      </div>
    </div>
  );
};
