import React from 'react'
import GearIcon from '../../../../../ui/Icons/GearIcon'
import ListBulletedSpacedIcon from '../../../../../ui/Icons/ListBulletedSpacedIcon'
import SearchIcon from '../../../../../ui/Icons/SearchIcon'

export default function BagsHeader({ tokenName, setTokenName }) {
  return (
    <div
      height='20px'
      spacing='14px'
      direction='row'
      class='Flex-sc-s34pwh-0 Stack-sc-aw8yms-0 ejusMF dQWtrb'
      style={{ position: 'relative' }}
    >
      <div class='CollectionItems__SearchInputRoot-sc-djuqct-0 fOWjjq'>
        <div
          class='SearchInput__InputRoot-sc-1wf23qs-0 eZyLtz'
          style={{ height: '32px' }}
        >
          <div class='input-prefix'>
            <div
              width='20px'
              height='20px'
              name='magnifying-glass'
              color='grey300'
              class='Flex-sc-s34pwh-0 Icon__StyledFlex-sc-1an4bgh-0 ejcTzg BHLgi'
            >
              <SearchIcon width={12} height={12} />
            </div>
          </div>
          <input
            id='token-id-search'
            type='text'
            placeholder='Token Name'
            autoComplete='off'
            value={tokenName}
            onChange={(e) => {
              setTokenName(e.target.value)
            }}
            style={{ width: '100px' }}
          />
        </div>
      </div>
      <button class='IconButton__StyledButton-sc-1fc64g3-0 gPjzRQ'>
        <div
          width='20px'
          height='20px'
          name='list-bulleted-spaced'
          color='yellow300'
          class='Flex-sc-s34pwh-0 Icon__StyledFlex-sc-1an4bgh-0 ejcTzg ckzUPf'
        >
          <ListBulletedSpacedIcon
            width={15}
            height={14}
            color='rgb(246, 174, 45)'
          />
        </div>
      </button>
      <button
        width='20px'
        height='20px'
        name='gear'
        color='grey300'
        class='Flex-sc-s34pwh-0 Icon__StyledFlex-sc-1an4bgh-0 ejcTzg HyzzX'
        style={{ cursor: 'pointer' }}
      >
        <GearIcon width={14} height={14} />
      </button>
    </div>
  )
}
