import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Providers from './components/providers/providers'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { router } from './routes'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Providers>
    <RouterProvider router={router} />
    <ToastContainer theme='dark' autoClose={2000}  hideProgressBar={true} />
  </Providers>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
