import React from 'react'
import s from './LabelName.module.scss'

export default function LabelName({ name, setName }) {
  return (
    <input
      id='label-name'
      value={name}
      placeholder='Name...'
      onChange={(e) => setName(e.target.value)}
      style={{width: '100%'}}
      className={s.field}
      // className='p-2.5 outline-none rounded bg-transparent border border-sub'
    />
  )
}
