import React, { useEffect } from 'react'

import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import backIcon from './backbutton2.png'; // Ensure the correct path to the image file
import homeIcon from './hom5.png'; // Ensure the correct path to the image file

import { useSelector } from 'react-redux'
import { useActions } from '../../../hooks/use-actions';
import styles from './Modal.module.scss'

export default function ModalLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(state => state.modalSlice.user)
    const { savedQueries, query, autobuy, autobuyQuery } = useSelector((state) => state.modalSlice)
  const { resetQuery } = useActions()

    useEffect(() => {
        // Scroll to the top of the page on component mount
        window.scrollTo(0, 0);
    }, []); // Empty dependency array means this will only run once, on mount


        function truncateString(str) {
            if (str.length > 12) {
                return str.slice(0, 12) + '...';
            }
            return str;
        }

    let autoBuyName = null
    let filterName = null 

    if (query !== undefined && query.name !== undefined) {

        filterName = truncateString(query.name)
    }

    if (autobuy !== undefined && autobuy.filterName !== undefined) {



        autoBuyName = truncateString(autobuy.filterName)

    }

    const goBack = () => {

        resetQuery()
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/modal', { replace: true });
        }
    };

    const goHom = () => {

        resetQuery()
    
            navigate('/modal', { replace: true });

    };
    
    const getPageName = () => {
        const pathParts = location.pathname.split('/').filter(Boolean);
        const currentPage = pathParts[pathParts.length - 1];

        let pageTitle = null

        switch (currentPage) {
            case 'show-filters':
                pageTitle = 'PROFILE MANAGER';
                break;
            case 'edit-filter-gm':
                pageTitle = `EDIT PROFILE (${filterName})`;
                break;
            case 'show-bags':
                pageTitle = 'SIMULATED BUYS';
                break;
            case 'show-bags-real':
                pageTitle = 'AUTOMATED BUYS';
                break;
            case 'generate-wallet':
                pageTitle = 'WALLET MANAGER';
                break;

            case 'generate-referral-code':
                pageTitle = 'REFERRAL LIST';
                break;

            case 'create-filter':
                pageTitle = `CREATE PROFILE`;
                break;

            case 'auto-buy':
                pageTitle = `CREATE AUTOBUY (${autoBuyName})`;
                break;

            case 'edit-auto-buy':
                pageTitle = `EDIT AUTOBUY`;
                break;

            default:
                pageTitle = currentPage.charAt(0).toUpperCase() + currentPage.slice(1).replace('-', ' ');

                break;
        }



        return pageTitle
    };

    return (
        <div style={{ padding: 12 }}>
      <div style={{
        borderBottom: '1px solid rgb(36 33 33)',
        paddingBottom: 15,
        marginBottom: 15,
        fontSize: 15,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
      }} onClick={goBack}>
        <img src={backIcon} alt="Back" style={{ width: 25, height: 25, position: 'relative', marginRight: '15px' }} />
        <span className={styles.header}>{getPageName()}</span>
      </div>
              <div onClick={goHom}><img src={homeIcon} alt="Home" style={{ width: 27, height: 27, position: 'absolute', right: '6px', top: '10px' }} /></div>

      <Outlet />
    </div>
    );
}