import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../hooks/use-actions'
import s from './Logs.module.scss'
import TimeAgo from 'timeago-react';
import moment from 'moment';

const TimeAgoComponent = ({ timestamp }) => {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    // Update the current time every minute
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Convert Unix timestamp to JavaScript Date object
  const dateObject = moment.unix(timestamp).toDate();

  return (
    <div>
      {/* Display the relative time using TimeAgo */}
      <TimeAgo datetime={dateObject} />

      {/* Alternatively, you can customize the display format */}
      {/* <TimeAgo datetime={dateObject} locale="en_US" formatter={(value, unit, suffix) => `${value} ${unit[0]} ${suffix}`} /> */}
    </div>
  );
};

export default function GenerateCode() {
  const logs = useSelector((state) => state.modalSlice.logs)
  const stats = useSelector((state) => state.modalSlice.stats)
  const user = useSelector(state => state.modalSlice.user)

const { getStats } = useActions()

  const fetchDataAsync = async () => {

    try {

      await getStats(user);

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    // Initial fetch
    fetchDataAsync();

    // Fetch data every 30 seconds
    const intervalId = setInterval(fetchDataAsync, 30000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [user]);

  return  logs !== undefined ? (
        
           <CodesList />
          ) : (<p></p>)

}

const CodesList = () => {
  const logs = useSelector((state) => state.modalSlice.logs)
  const stats = useSelector((state) => state.modalSlice.stats)

  return (
    <div className={s.codes}>



        <div style={{ float: 'left', width: '100%' }}>
          <h3 style={{ float: 'left', display: 'block' }}>TOTAL USERS</h3>
          <div style={{ float: 'right' }}>{stats.totalUsers}</div>
        </div>

        <div style={{ float: 'left', width: '100%' }}>
          <h3 style={{ float: 'left', display: 'block' }}>ACTIVE USERS</h3>
          <div style={{ float: 'right' }}>{stats.activeUsers !== undefined ? stats.activeUsers.length : 0}</div>
        </div>

        <div style={{ float: 'left', width: '100%', marginBottom: '30px' }}>
          <h3 style={{ float: 'left', display: 'block' }}>INACTIVE USERS</h3>
          <div style={{ float: 'right' }}>{stats.inactiveUsers !== undefined ? stats.inactiveUsers.length : 0}</div>
        </div>


      <div style={{marginTop: '70px', marginBottom: '70px'}}>
      <br />
        <h3>LEADERBOARD</h3>
        <br />
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
          {stats.leaderboard[0] !== undefined ? (
            stats.leaderboard[0].map((user, userIdx) => (
              <li key={user.id} style={{ display: 'block' }}>
                <div style={{ float: 'left', width: '100%' }}>
                  <span style={{ float: 'left', display: 'block' }}><a href={'https://t.me/' + user.telegram_name}>{user.telegram_name}</a></span>
                  <div style={{ float: 'right' }}>{user.referred_array_length}</div>
                </div>
              </li>
            ))
          ) : (
            <li style={{ display: 'block' }}>No logs</li>
          )}
        </ul>
      </div>


      <div style={{marginTop: '70px', marginBottom: '70px'}}>
      <br />
        <h3>FILTER CREATIONS</h3>
        <br />
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
          {logs.filterCreations !== undefined ? (
            logs.filterCreations.map((user, userIdx) => (
              <li key={user.id} style={{ display: 'block' }}>
                <div style={{ float: 'left', width: '100%' }}>
                  <span style={{ float: 'left', display: 'block' }}>{user.telegram_name}</span>
                  <div style={{ float: 'right' }}><TimeAgoComponent timestamp={user.timestamp} /></div>
                </div>
              </li>
            ))
          ) : (
            <li style={{ display: 'block' }}>No logs</li>
          )}
        </ul>
      </div>

      <div style={{marginBottom: '70px'}}>
      <br />
        <h3>APP LOGINS</h3>
        <br />
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
          {logs.appLogins !== undefined ? (
            logs.appLogins.map((user, userIdx) => (
              <li key={user.id} style={{ display: 'block' }}>
                <div style={{ float: 'left', width: '100%' }}>
                  <span style={{ float: 'left', display: 'block' }}>{user.telegram_name}</span>
                  <div style={{ float: 'right' }}><TimeAgoComponent timestamp={user.timestamp} /></div>
                </div>
              </li>
            ))
          ) : (
            <li style={{ display: 'block' }}>No logs</li>
          )}
        </ul>
      </div>
<br />
      <div><br />
<br />
        <h3>BOT LOGINS</h3>
        <br />
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
          {logs.botLogins !== undefined ? (
            logs.botLogins.map((user, userIdx) => (
              <li key={user.id} style={{ display: 'block' }}>
                <div style={{ float: 'left', width: '100%' }}>
                  <span style={{ float: 'left', display: 'block' }}>{user.telegram_name}</span>
                  <div style={{ float: 'right' }}><TimeAgoComponent timestamp={user.timestamp} /></div>
                </div>
              </li>
            ))
          ) : (
            <li style={{ display: 'block' }}>No logs</li>
          )}
        </ul>
      </div>

    </div>
  )
}
