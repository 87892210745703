import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../hooks/use-actions'
import Button from '../../../ui/Button/Button'
import s from './GenerateWallet.module.scss'
import { AiOutlineCopy } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from 'react-toastify'
import styles from './GenerateWallet.module.scss';
import copypatseIcon from './copypatse.png';
import { BiX } from 'react-icons/bi'

export default function GenerateWallet() {
      const { deleteWallet } = useActions()

    const wallets = useSelector((state) => state.modalSlice.wallets)
    const user = useSelector((state) => state.modalSlice.user)
   const [textToCopy, setTextToCopy] = useState('Text to copy');
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = (addy, name) => {
    // Create a text area element to hold the text to be copied
    const textArea = document.createElement('textarea');
    textArea.value = addy

    // Append the text area element to the document
    document.body.appendChild(textArea);

    // Select the text within the text area and copy it to the clipboard
    textArea.select();
    document.execCommand('copy');

    // Remove the text area element from the document
    document.body.removeChild(textArea);

    // Set the state to indicate that the text has been copied
    setIsCopied(true);

        toast.success(`Copied ${name}'s address to clipboard`, {
              icon: false,
    className: styles.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })
  }

    const { generateWallet } = useActions()

    const generateNewWallet = async () => {

      let data = {
        
        telegram_id: user.telegram_id,
        hash: user.hash
      
      }

      if (wallets.length > 2) {

        return toast.error(`Max of three wallets`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        })

      } else {

        generateWallet(data)

        toast.success(`Wallet generated`, {
              icon: false,
    className: styles.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })

      }

    }

  const handleDelete = (id, name) => {

        console.log('id', id)
        console.log('user', user)

            let data = {
      
      id,
      telegram_id: user.telegram_id,

    }


    deleteWallet(data)
        toast.success(`Removed '${name}' WALLET`, {
              icon: false,
    className: styles.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })
  };


  return (
    <div className={styles.walletsContainer}>
      <div className={styles.walletCard}>
      
      {
        wallets.length > 0 ? (
          wallets.map((wallet) => (
            <div key={wallet.id} className={styles.walletContainer}>
              <div className={styles.iconCircle}>
                <BiX size={17} color="#fff"  onClick={() => handleDelete(wallet.id, wallet.name)} />
              </div>
              <div className={styles.walletHeader}>
                <div className={styles.walletTitle}>{wallet.name}</div>
                  <div className={`${styles.value} ${styles.bag}`}>{wallet.balance} SOL</div>
                </div>
                <div className={styles.walletRow}>
                  <div className={styles.walletAddress}>{wallet.address}</div>
                <div>
                <a href="#">
                  <img src={copypatseIcon} onClick={() => handleCopyToClipboard(wallet.address, wallet.name)}  className={styles.icon} />
                </a>
              </div>
            </div>
          </div>
          )
        )
      ) : ''
    }

    {
      wallets.length === 0 ? (<p className={styles.securityNotice}>Generate a wallet to get started</p>) 
                           : ''
    }

    {
      wallets.length <= 2 && <Button className={s.saveButton} onClick={() => generateNewWallet()}>Generate Wallet</Button>
    }

    {
      wallets.length > 0 &&  (<p className={s.disclaimer}>To ensure security, you can only retreive your private keys through our telegram bot with the /keys command</p>)
    }

      </div>
    </div>
  );
}





 