import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../../hooks/use-actions'

import queryString from 'query-string';

export default function Dashboard() {

  const { preVerify } = useActions() // api
  const { sync, cmb_address } = useSelector((state) => state.modalSlice)

  const url = window.location.href
  const parsed = queryString.parseUrl(url, { decode: false })
  const hash = parsed.query.k

  const fetchDataAsync = async () => {

    try {

      // console.log('hash', hash)
      await preVerify({hash: hash});

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => { 
    
    fetchDataAsync()

  }, [])

  return (
    <div className='App'>
      {
        cmb_address === null 
          ? <div><span>Hello {sync.username !== null ? sync.username : sync.telegram_id}</span><br />Please connect your wallet and sign to verify your account</div>
          : <span>You are already synced with {cmb_address}</span>
      }
    </div>
  )

}