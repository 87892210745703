import React from 'react'

export default function EthIcon({ color = 'grey', width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 16'
      xmlns='http://www.w3.org/2000/svg'
      fill={color}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        fill='white'
        d='M4.99929 0H4.33272V0.666572H3.99943V1.33314H3.33286V2.66629H2.66629V3.33286H1.99972V4.666H1.33314V5.33257H0.666572V6.66572H0V7.33229H0.666572V7.99886H1.99972V8.66543H3.33286V9.332H4.33272V9.99858H4.99929V9.332H5.99915V8.66543H7.33229V7.99886H8.66543V7.33229H9.33201V6.66572H8.66543V5.33257H7.99886V4.666H7.33229V3.33286H6.66572V2.66629H5.99915V1.33314H5.33257V0.666572H4.99929V0ZM3.33286 8.66543V7.99886H1.99972V7.33229H0.666572V6.66572H1.33314V5.33257H1.99972V4.666H2.66629V3.33286H3.33286V2.66629H3.99943V1.33314H4.33272V8.66543H3.33286Z'
      ></path>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        fill='white'
        d='M0 9.332H0.666572V10.6651H1.33314V11.9983H1.99972V12.6649H2.66629V13.999H3.33286V14.6656H3.99943V15.3334H4.33272V16H4.99929V15.3334H4.666V15.3321H5.33257V14.6656H5.99915V13.999H6.66572V12.6649H7.33229V11.9983H7.99886V10.6651H8.66543V9.332H9.33201V8.66543H8.66543V9.332H7.33229V9.99858H5.99915V10.6645L4.99929 10.6651V11.3317H4.33272V10.6651H3.33286V9.99858H1.99972V9.332H0.666572V8.66543H0V9.332ZM1.99972 10.6651H1.33314V9.99858H1.99972V10.6651ZM1.99972 10.6651V11.9983H2.66629V12.6649H3.33286V13.999H3.99943V14.6636H4.666V14.6656H5.33257V14.6636H4.666V11.9983H4.33272V11.3317H3.33286V10.6651H1.99972Z'
      ></path>
    </svg>
  )
}
