import React, { useState } from 'react'
import BarChartIcon from '../../ui/Icons/BarChartIcon'
import DotChartIcon from '../../ui/Icons/DotChartIcon'
import Tabs from '../../ui/Tabs/Tabs'

export default function DashboardCollectionsCharts() {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div style={{ padding: 20 }}>
      <Tabs
        tabs={[
          { text: 'DEPTH', icon: <BarChartIcon width={16} height={14} /> },
          { text: 'SALES', icon: <DotChartIcon width={16} height={14} /> }
        ]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab === 0 && <div>First tab</div>}
      {activeTab === 1 && <div>Second tab</div>}
    </div>
  )
}
