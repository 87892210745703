import cn from 'clsx'
import React from 'react'
import s from './Switcher.module.scss'

export default function Switcher({ isChecked, setIsChecked }) {
  return (
    <div
      className={cn(s.container, isChecked && s.active)}
      onClick={() => setIsChecked(!isChecked)}
    >
      <span className={s.indicator} />
      <span className={s.text}>{isChecked ? 'On' : 'Off'}</span>
    </div>
  )
}
