import { dashboardSlice } from './slices/dashboard-slice'
import {
  addQuery,
  deleteBag,
  deleteQuery,
  deleteBagQuery,
  deleteBagReal,
  getAllQueries,
  getStats,
  getTokens,
  addAutoBuy,
  updateAutoBuy,
  deleteAutoBuy,
  toggleAutoBuy,
  getBags,
  getBagsReal,
  sellToken,
  toggleFilter,
  preVerify,
  saveReferrer,
  generateWallet,
  deleteWallet,
  modalSlice,
  updateQuery
} from './slices/modal-slice'

export const rootActions = {
  ...dashboardSlice.actions,
  ...modalSlice.actions,
  getAllQueries,
  getStats,
  getTokens,
  addAutoBuy,
  updateAutoBuy,
  deleteAutoBuy,
  toggleAutoBuy,
  getBags,
  getBagsReal,
  sellToken,
  toggleFilter,
  preVerify,
  saveReferrer,
  addQuery,
  generateWallet,
  deleteWallet,
  updateQuery,
  deleteQuery,
  deleteBagQuery,
  deleteBagReal
}
