import React from 'react'
import BagsTable from './BagsTable'

export default function Bags({
  setSelectedSell,
  selectedSell,
  setIsAll,
  setPrice
}) {
  return (
    <div class='styles__DataTableStyleDiv-sc-17dfubf-1 egzFid'>
      <div
        role='table'
        spacing='[object Object]'
        class='DataTableMediaLarge__StyledTable-sc-q5a38r-0 esJunV'
        style={{
          minWidth: '0px',
          position: 'relative',
          borderTop: '1px solid grey'
        }}
      >
        <BagsTable
          setSelectedSell={(value) => {
            setSelectedSell(value)
          }}
          selectedSell={selectedSell}
          setIsAll={(value) => setIsAll(value)}
          setPrice={(value) => {
            setPrice(value)
          }}
        />
      </div>
    </div>
  )
}
