import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { dashboardSlice } from './slices/dashboard-slice'
import { modalSlice } from './slices/modal-slice'

const rootReducer = combineReducers({
  dashboardSlice: dashboardSlice.reducer,
  modalSlice: modalSlice.reducer
})

export const store = configureStore({
  reducer: rootReducer
})
