export const data = {
  getAllItems() {
    const items = []
    this.options.forEach((option) => items.push(...option.items))
    return items
  },



  



  options: [
    {
      id: 1,
      title: 'Authoritive',
      items: [
        {
          id: 1,
          name: 'Not Mutable',
          desc: ''
        },
        {
          id: 2,
          name: 'Not Mintable',
          desc: ''
        },
        {
          id: 3,
          name: 'Not Freezeable',
          desc: ''
        }
      ]
    },



    {
      id: 2,
      title: 'Initial Liquidity',
      items: [


        {
          id: 9,
          name: 'at least 25 SOL',
          desc: ''
        },

        {
          id: 21,
          name: 'at least 50 SOL',
          desc: ''
        },

        {
          id: 22,
          name: 'at least 100 SOL',
          desc: ''
        },

        {
          id: 23,
          name: 'at least 250 SOL',
          desc: ''
        },

      ]
    },
   
    {
      id: 3,
      title: 'Liquidity',
      items: [
        {
          id: 18,
          name: 'Burned',
          desc: ''
        },
      ]
    },


    {
      id: 4,
      title: 'Socials Exist',
      items: [
        {
          id: 4,
          name: 'Telegram',
          desc: ''
        },
        {
          id: 5,
          name: 'Twitter',
          desc: ''
        },
        {
          id: 6,
          name: 'Website',
          desc: ''
        }
      ]
    },






    {
      id: 5,
      title: 'Trending',
      items: [



        {
          id: 16,
          name: '@SOLTRENDING via Safeguard',
          desc: ''
        },

                {
          id: 19,
          name: '@trendingsevents via Deluge',
          desc: ''
        },


      ]
    },





    {
      id: 5,
      title: 'Misc',
      items: [

        {
          id: 10,
          name: 'Pump Fun Token',
          desc: ''
        },

       
      ]
    },

  ]
}





/*

    {
      id: 5,
      title: 'Dextools',
      items: [



        {
          id: 17,
          name: 'Fast Tracked',
          desc: ''
        },

                {
          id: 20,
          name: 'Hot Pairs',
          desc: ''
        },


      ]
    },
    
    1:  { signal_mutable: true },
    2:  { signal_mintable: true },
    3:  { signal_freezeable: true },
    4:  { signal_telegram: true },
    5:  { signal_twitter: true },
    6:  { signal_website: true },
    7:  { signal_initial_liquidity: 1 },
    8:  { signal_initial_liquidity: 5 },
    9: { signal_initial_liquidity: 25 },
    10: { signal_pump: true },
    11: { signal_moonshot: true },

        12: { signal_distro_top5: 10 },
    13: { signal_distro_top10: 10 },
    14: { signal_distro_holders: 10 },
    15: { signal_distro_owner: 10 },


        16: { signal_trending: true },
    17: { signal_dextools: true },
    18: { signal_burned: true }
    */
