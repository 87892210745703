import React from 'react'

export default function ClockIcon({ color = 'grey', width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M11.9778 0.600098H2.0222V2.02232H0.599976V11.9779H2.0222V13.4001H11.9778V11.9779H13.4V2.02232H11.9778V0.600098ZM11.9778 2.02232V11.9779H2.0222V2.02232H11.9778ZM6.28886 3.44454H7.71109V7.71121H10.5555V9.13343H6.28886V3.44454Z'></path>
    </svg>
  )
}
