import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useActions } from '../../../hooks/use-actions'
import Button from '../../ui/Button/Button'

import { Link } from 'react-router-dom'
import styles from './Modal.module.scss'
import oculusLogo from './oculus.gif';


export default function Modal() {



    return (
    <div className={styles.mainContainer}>
      <div className={styles.mainCard}>
            <img src={oculusLogo} style={{ maxWidth: '300px', margin: '25px 0' }}  />
            <div className={styles.mainTitle}>OCULUS X SOLANA</div>
        </div>
    </div>)

}