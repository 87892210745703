import React from 'react'

export default function GavelIcon({ color = 'grey', width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_4955_275392)'>
        <rect
          x='4'
          y='12'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 4 12)'
        ></rect>
        <rect
          x='2.66675'
          y='13.3335'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 2.66675 13.3335)'
        ></rect>
        <rect
          x='1.33325'
          y='13.3335'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 1.33325 13.3335)'
        ></rect>
        <rect
          y='14.6665'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 0 14.6665)'
        ></rect>
        <rect
          x='1.33325'
          y='14.6665'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 1.33325 14.6665)'
        ></rect>
        <rect
          y='16'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 0 16)'
        ></rect>
        <rect
          x='8'
          y='16'
          width='1.04348'
          height='8'
          transform='rotate(-90 8 16)'
        ></rect>
        <rect
          x='9.39136'
          y='14.6089'
          width='1.04348'
          height='5.21739'
          transform='rotate(-90 9.39136 14.6089)'
        ></rect>
        <rect
          x='2.66675'
          y='12'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 2.66675 12)'
        ></rect>
        <rect
          x='4'
          y='10.6665'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 4 10.6665)'
        ></rect>
        <rect
          x='5.33325'
          y='9.3335'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 5.33325 9.3335)'
        ></rect>
        <rect
          x='5.33325'
          y='10.6665'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 5.33325 10.6665)'
        ></rect>
        <rect
          x='6.66675'
          y='9.3335'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 6.66675 9.3335)'
        ></rect>
        <rect
          x='13.3333'
          y='9.3335'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 13.3333 9.3335)'
        ></rect>
        <rect
          x='12'
          y='10.6665'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 12 10.6665)'
        ></rect>
        <rect
          x='14.6667'
          y='8'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 14.6667 8)'
        ></rect>
        <rect
          x='13.3333'
          y='6.6665'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 13.3333 6.6665)'
        ></rect>
        <rect
          x='12'
          y='5.3335'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 12 5.3335)'
        ></rect>
        <rect
          x='10.6667'
          y='4'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 10.6667 4)'
        ></rect>
        <rect
          x='9.33325'
          y='2.6665'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 9.33325 2.6665)'
        ></rect>
        <rect
          x='8'
          y='1.3335'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 8 1.3335)'
        ></rect>
        <rect
          x='6.66675'
          y='2.6665'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 6.66675 2.6665)'
        ></rect>
        <rect
          x='5.33325'
          y='4'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 5.33325 4)'
        ></rect>
        <rect
          x='4'
          y='5.3335'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 4 5.3335)'
        ></rect>
        <rect
          x='4'
          y='6.6665'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 4 6.6665)'
        ></rect>
        <rect
          x='9.33325'
          y='1.3335'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 9.33325 1.3335)'
        ></rect>
        <rect
          x='14.6667'
          y='6.6665'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 14.6667 6.6665)'
        ></rect>
        <rect
          x='9.33325'
          y='12'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 9.33325 12)'
        ></rect>
        <rect
          x='5.33325'
          y='6.6665'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 5.33325 6.6665)'
        ></rect>
        <rect
          x='8'
          y='4'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 8 4)'
        ></rect>
        <rect
          x='6.66675'
          y='5.3335'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 6.66675 5.3335)'
        ></rect>
        <rect
          x='12'
          y='8'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 12 8)'
        ></rect>
        <rect
          x='10.6667'
          y='9.3335'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 10.6667 9.3335)'
        ></rect>
        <rect
          x='6.66675'
          y='8'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 6.66675 8)'
        ></rect>
        <rect
          x='8'
          y='9.3335'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 8 9.3335)'
        ></rect>
        <rect
          x='9.33325'
          y='10.6665'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 9.33325 10.6665)'
        ></rect>
        <rect
          x='10.6667'
          y='12'
          width='1.33333'
          height='1.33333'
          transform='rotate(-90 10.6667 12)'
        ></rect>
      </g>
      <defs>
        <clipPath id='clip0_4955_275392'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(16) rotate(90)'
          ></rect>
        </clipPath>
      </defs>
    </svg>
  )
}
