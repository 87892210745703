import React from 'react'

export default function UsersIcon({ color = 'grey', width = 16, height = 16 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill={color}
      viewBox='0 0 20 20'
    >
      <path d='M9.25 3H4.75V4.6H3.25V9.4H4.75V4.6H9.25V3ZM9.25 9.4H4.75V11H9.25V9.4ZM9.25 4.6H10.75V9.4H9.25V4.6ZM1 14.2H2.5V12.6H11.5V14.2H2.5V17.4H11.5V14.2H13V19H1V14.2Z'></path>
      <path d='M10.25 4.6V7.4H10.75V4.6H10.25Z'></path>
      <path d='M12.75 7.4V9H16.25V7.4H17.75V2.6H16.25V1H11.75V2.6H16.25V7.4H12.75Z'></path>
      <path d='M15 17H20V12.2H18.5V10.6H13.5V12.2H18.5V15.4H15V17Z'></path>
      <path d='M13 15.4V17H11.5V15.4H13Z'></path>
      <path d='M9.5 12.6H8V14.2H9.5V12.6Z'></path>
    </svg>
  )
}
