import cn from 'clsx'
import React from 'react'
import s from './Button.module.scss'

export default function Button({ children, className = '', ...props }) {
  return (
    <button className={cn(s.button, className)} {...props}>
      {children}
    </button>
  )
}
