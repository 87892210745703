import React from 'react'

export default function SniperIcon({
  color = 'gray',
  width = 16,
  height = 16
}) {
  return (
    <svg
      width={width}
      height={height}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      fill={color}
    >
      <g>
        <circle style={{ fill: '#FE834D' }} cx='288.681' cy='256' r='8.17' />
        <circle style={{ fill: '#FE834D' }} cx='256' cy='256' r='8.17' />
        <circle style={{ fill: '#FE834D' }} cx='223.319' cy='256' r='8.17' />
      </g>
      <path
        d='M503.83,247.83h-46.478C453.157,143.191,368.809,58.842,264.17,54.648V8.17c0-4.512-3.658-8.17-8.17-8.17
				c-4.512,0-8.17,3.658-8.17,8.17v46.478C143.191,58.842,58.842,143.191,54.648,247.83H8.17C3.658,247.83,0,251.488,0,256
				s3.658,8.17,8.17,8.17h46.471c1.816,44.632,18.452,87.602,47.321,121.786c2.911,3.448,8.067,3.881,11.513,0.971
				c3.448-2.911,3.882-8.066,0.971-11.513c-26.382-31.24-41.652-70.466-43.457-111.243h46.117c4.512,0,8.17-3.658,8.17-8.17
				s-3.658-8.17-8.17-8.17H70.998c4.173-95.627,81.204-172.658,176.832-176.831v46.107c0,4.512,3.658,8.17,8.17,8.17
				s8.17-3.658,8.17-8.17V70.999c95.627,4.173,172.658,81.203,176.832,176.831h-46.108c-4.512,0-8.17,3.658-8.17,8.17
				s3.658,8.17,8.17,8.17h46.108c-4.173,95.627-81.203,172.658-176.832,176.831v-46.107c0-4.512-3.658-8.17-8.17-8.17
				c-4.512,0-8.17,3.658-8.17,8.17v46.117c-40.778-1.805-80.006-17.075-111.247-43.458c-3.448-2.913-8.603-2.477-11.513,0.971
				c-2.912,3.448-2.476,8.602,0.971,11.513c34.185,28.869,77.155,45.506,121.788,47.322v46.471c0,4.512,3.658,8.17,8.17,8.17
				c4.512,0,8.17-3.658,8.17-8.17v-46.478c104.639-4.195,188.988-88.543,193.182-193.182h46.479c4.512,0,8.17-3.658,8.17-8.17
				C512,251.488,508.342,247.83,503.83,247.83z'
      />
    </svg>
  )
}
