import React, { useState } from 'react'
import EthIcon from '../../../ui/Icons/EthIcon'
import FuelIcon from '../../../ui/Icons/FuelIcon'
import SniperIcon from '../../../ui/Icons/SniperIcon'
import SpeedometerIcon from '../../../ui/Icons/SpeedometerIcon'
import Switcher from '../../../ui/Switcher/Switcher'
import DropdownSelect from '../DropdownSelect'

export default function LeftBarSniper({
  addWallet,
  setSelectedItems,
  setEditable,
  sippageMinus,
  setSippage,
  sippage,
  sippagePlus,
  gasMinus,
  gas,
  gasPlus,
  snipeClick,
  selectedItems,
  amount,
  setAmount,
  walletOption
}) {
  const [isChecked, setIsChecked] = useState(false)

  return (
    <div className='css-12khlxv'>
      <div className='css-109c0pv'>
        <div
          style={{
            marginBottom: '30px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <SniperIcon width={15} height={15} color='#f6ae2d' />
          <div style={{ marginLeft: '10px', fontSize: '14px' }}>SNIPER</div>
        </div>
        <DropdownSelect
          option={walletOption}
          addable={true}
          addWallet={(value) => {
            addWallet(value)
          }}
          setSelectedItem={(value) => {
            setSelectedItems([...selectedItems, value.id])
          }}
          setEditable={(id) => {
            setEditable(id)
          }}
          editable={true}
        />
        <div className='stepbar-wrap'>
          <div className='button-minus' onClick={sippageMinus}>
            -
          </div>
          <div className='step-content'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px' }}>SLIPPAGE</div>
              <SpeedometerIcon width={15} height={10} />
            </div>
            <input
              pattern='^[0-9]*$'
              onChange={(e) => {
                if (100 > e.target.value > 0)
                  setSippage(Number(e.target.value.replace(/[^0-9\.]/g, '')))
              }}
              value={sippage}
              style={{
                width: '100px',
                border: 'none',
                userSelect: 'none',
                outline: 'none',
                background: '#080404',
                color: 'grey',
                textAlign: 'right'
              }}
            />
          </div>
          <div className='button-plus' onClick={sippagePlus}>
            +
          </div>
        </div>
        <div className='stepbar-wrap'>
          <div className='button-minus' onClick={gasMinus}>
            -
          </div>
          <div className='step-content'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px' }}>Gas</div>
              <FuelIcon width={13} height={15} />
            </div>
            <div>{gas}</div>
          </div>
          <div className='button-plus' onClick={gasPlus}>
            +
          </div>
        </div>
        <div
          style={{
            marginTop: 20,
            display: 'flex',
            alignItems: 'center',
            gap: 12
          }}
        >
          <div>Setting 1</div>
          <Switcher isChecked={isChecked} setIsChecked={setIsChecked} />
        </div>
        <div className='snipe-wrap'>
          <button className='snipe-btn' onClick={snipeClick}>
            <div>SNIPE WITH {selectedItems.length} WALLETS</div>
          </button>
          <div className='snipe-btn-right'>
            <input
              type='text'
              value={amount}
              style={{
                width: '68px',
                background: 'transparent',
                borderRadius: '8px',
                color: 'white',
                outline: 'none',
                fontFamily: 'Proto Mono',
                height: '30px',
                padding: '0px !important',
                paddingLeft: '8px',
                marginTop: '-2px',
                paddingTop: '0px',
                paddingBottom: '0px'
              }}
              onChange={(event) => {
                setAmount(event.target.value)
              }}
            ></input>
            <div style={{ position: 'absolute', top: '6px', right: '8px' }}>
              <EthIcon color='white' width={10} height={16} />
            </div>
          </div>
        </div>
        <div className='statistic'>
          <div
            width='20px'
            height='20px'
            name='gauge'
            color='grey300'
            className='Flex-sc-s34pwh-0 Icon__StyledFlex-sc-1an4bgh-0 ejcTzg gnLMRO'
          >
            <SpeedometerIcon width={15} height={8} color='white' />
            <div
              color='green300'
              class='Text__TextRoot-sc-m23s7f-0 iMtIGg'
              style={{ marginLeft: '5px' }}
            >
              SS GWEI
            </div>
          </div>
          <FuelIcon width={13} height={15} color='white' />
          <div color='grey300' class='Text__TextRoot-sc-m23s7f-0 eXWcRH'>
            GAS PRIORITY
          </div>
          <div color='green300' class='Text__TextRoot-sc-m23s7f-0 iMtIGg'>
            2
          </div>
          <div color='grey300' class='Text__TextRoot-sc-m23s7f-0 eXWcRH'>
            FEE
          </div>
          <div color='green300' class='Text__TextRoot-sc-m23s7f-0 iMtIGg'>
            80
          </div>
        </div>
      </div>
    </div>
  )
}
