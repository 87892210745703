// Modal.setAppElement('#yourAppElement')

import { Box, Modal } from '@mui/material'
import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import s from './Holders.module.scss'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'black',
  border: '1px solid grey',
  borderRadius: 1,
  boxShadow: 24,
  p: 4
}

export default function Holders() {
  const [isOpen, setIsOpen] = useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div>
      <button style={{ color: 'white' }} onClick={openModal}>
        Open Modal
      </button>
      <Modal open={isOpen} onClose={closeModal} contentLabel='Example Modal'>
        <Box sx={style}>
          <h2>Hello</h2>
          <button onClick={closeModal}>close</button>
          <div>I am a modal</div>
          <AiOutlineClose className={s.modalClose} onClick={closeModal} />
        </Box>
      </Modal>
    </div>
  )
}
