import React, { useEffect, useRef, useState } from 'react'

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import RadioOutlinedIcon from '@mui/icons-material/RadioOutlined'

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus()
  }

  return [htmlElRef, setFocus]
}

const DropdownSelect = ({
  option,
  heart,
  editable,
  deletable,
  icon,
  addable,
  addWallet,
  setSelectedItem
}) => {
  const [arrow, setArrow] = useState(false)
  const [optionList, setOptionList] = useState(option)
  const [addState, setAddState] = useState(false)
  const [wallet, setWallet] = useState('')
  const [inputRef, setInputFocus] = useFocus()
  const arrowClick = () => {
    setArrow(!arrow)
    console.log(arrow)
  }

  const selectItem = (value, id) => {
    const updatedOptionList = optionList.filter((item) => {
      if (item.id === id) {
        item.checked = value
      }
      return item
    })
    setOptionList(updatedOptionList)
  }

  const editOption = (id) => {
    const updatedOptionList = optionList.filter((item) => {
      if (item.id === id) {
        item.editState = !item.editState
      }
      return item
    })
    setOptionList(updatedOptionList)
  }

  const editDelete = (id) => {
    setOptionList(optionList.filter((item) => item.id != id))
  }

  const editItem = (value, id) => {
    const updatedOptionList = optionList.filter((item) => {
      if (item.id === id) {
        item.name = value
      }
      return item
    })
    setOptionList(updatedOptionList)
  }

  const favoriteOption = (id) => {
    const updatedOptionList = optionList.filter((item) => {
      if (item.id === id) {
        item.favorite = !item.favorite
      }
      return item
    })
    setOptionList(updatedOptionList)
  }

  const dropdownRef = useRef(null)
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setArrow(false)
    }
  }

  const handleAddWallet = () => {
    setAddState(!addState)
    setTimeout(() => {
      setArrow(true) // Set `arrow` to true after a short delay
    }, 10)
    setTimeout(() => {
      setInputFocus()
    }, 500)
  }

  return (
    <div
      ref={dropdownRef}
      style={
        (arrow && {
          boxShadow: '0 0 5px rgba(81, 203, 238, 1)',
          border: '1px solid rgba(81, 203, 238, 1)',
          borderRadius: '8px'
        }) ||
        {}
      }
    >
      <div
        className='select-box'
        style={
          (!arrow && {
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px'
          }) ||
          {}
        }
        onClick={arrowClick}
      >
        {(icon && (
          <div
            style={{
              width: '28px',
              height: '31.5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <RadioOutlinedIcon color='primary' style={{ width: '15px' }} />
          </div>
        )) ||
          null}
        <div className='selected-box'>
          {optionList &&
            Array.isArray(optionList) &&
            optionList.map((item, index) => {
              if (item.checked) {
                return (
                  <div
                    key={index}
                    className='selected-item'
                    onClick={() => {
                      selectItem(false, item.id)
                      setSelectedItem(item)
                    }}
                  >
                    {item.name} X
                  </div>
                )
              } else {
                return null
              }
            })}
          {optionList &&
            Array.isArray(optionList) &&
            optionList.filter((item) => item.checked === true).length === 0 && (
              <div style={{ marginLeft: '20px' }}>
                {addable && 'SELECT'} WALLETS
              </div>
            )}
        </div>
        <div
          style={{
            width: '28px',
            height: '31.5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {(arrow && <KeyboardArrowDownIcon sx={{ color: 'grey' }} />) || (
            <KeyboardArrowUpIcon sx={{ color: 'grey' }} />
          )}
        </div>
      </div>

      {arrow && optionList.length > 0 && (
        <div style={{ position: 'relative' }}>
          <div className='option-box'>
            {addable &&
              ((addState && (
                <input
                  ref={inputRef}
                  className='add-input'
                  value={wallet}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addWallet(e.target.value)
                      setAddState(false)
                      setWallet('')
                      setOptionList((prevOptions) => [
                        ...prevOptions,
                        { id: optionList.length + 1, name: e.target.value }
                      ])
                    }
                  }}
                  onChange={(e) => {
                    setWallet(e.target.value)
                  }}
                />
              )) || (
                <div
                  className='add-wallet'
                  onClick={() => {
                    // handleAddWallet();
                    setOptionList((prevOptions) => [
                      ...prevOptions,
                      {
                        id: optionList.length + 1,
                        name: 'WALLET #1 (9.8 ETH)'
                      }
                    ])
                  }}
                >
                  + RENERATE WALLET
                </div>
              ))}
            {optionList.map((item, index) => {
              return (
                <div className='option-item' key={index}>
                  <div className='item-info'>
                    <input
                      type='checkbox'
                      checked={item.checked}
                      onClick={(e) => {
                        selectItem(e.target.checked, item.id)
                        setSelectedItem(item)
                      }}
                    />
                    {heart &&
                      ((item.favorite && (
                        <FavoriteIcon
                          color='error'
                          style={{ width: '20px', height: '12px' }}
                          onClick={() => {
                            favoriteOption(item.id)
                          }}
                        />
                      )) || (
                        <FavoriteBorderOutlinedIcon
                          color='grey'
                          style={{ width: '20px', height: '12px' }}
                          onClick={() => {
                            favoriteOption(item.id)
                          }}
                        />
                      ))}
                    {(item.editState && (
                      <input
                        value={item.name}
                        style={{
                          width: '240px',
                          userSelect: 'none',
                          outline: 'none'
                        }}
                        onChange={(e) => {
                          editItem(e.target.value, item.id)
                        }}
                        onBlur={() => {
                          editOption(item.id)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            editOption(item.id)
                          }
                        }}
                      />
                    )) || <div>{item.name}</div>}
                    {item.eth && <div className='eth'>{item.eth}</div>}
                  </div>
                  <div className='edit'>
                    {editable && (
                      <ModeEditOutlineOutlinedIcon
                        color='grey'
                        style={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          editOption(item.id)
                        }}
                      />
                    )}
                    {deletable && (
                      <DeleteOutlinedIcon
                        color='error'
                        style={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          editDelete(item.id)
                        }}
                      />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default DropdownSelect
