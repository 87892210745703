import React, { useState } from 'react'
import GavelIcon from '../../../ui/Icons/GavelIcon'
import OpenHandIcon from '../../../ui/Icons/OpenHandIcon'
import UsersIcon from '../../../ui/Icons/UsersIcon'
import VerticalStackIcon from '../../../ui/Icons/VerticalStackIcon'
import Tabs from '../../../ui/Tabs/Tabs'
import Bags from './Tabs/Bags/Bags'
import BagsHeader from './Tabs/Bags/BagsHeader'
import Bids from './Tabs/Bids/Bids'
import Holders from './Tabs/Holders/Holders'
import Loans from './Tabs/Loans/Loans'

export default function MainBox({
  tokenName,
  setTokenName,
  selectedSell,
  setSelectedSell,
  setIsAll,
  setPrice
}) {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div id='COLLECTION_MAIN' className='collection-main'>
      <div
        class='section-header'
        style={{
          display: 'flex',
          padding: '0px 12px',
          marginBottom: '16px',
          justifyContent: 'space-between'
        }}
      >
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isBottomLine
          tabs={[
            {
              text: 'BAGS',
              icon: <VerticalStackIcon />
            },
            {
              text: 'BIDS',
              icon: <GavelIcon />
            },
            {
              text: 'LOANS',
              icon: <OpenHandIcon />
            },
            {
              text: 'HOLDERS',
              icon: <UsersIcon />
            }
          ]}
        />
        {[<BagsHeader tokenName={tokenName} setTokenName={setTokenName} />].at(
          activeTab
        )}
      </div>
      {[
        <Bags
          selectedSell={selectedSell}
          setSelectedSell={setSelectedSell}
          setIsAll={setIsAll}
          setPrice={setPrice}
        />,
        <Bids />,
        <Loans />,
        <Holders />
      ].at(activeTab)}
    </div>
  )
}
